import { SecondRegisterKycForm } from "@/models/authModels";
import { RegisterBrwrInfoEtc,RegisterStockLoanRequest } from "@/models/request/stockLoanRequests";
import { Module } from "vuex";
import { RootState } from "../index";

export interface BrwrRegisterState {
  second: {
    targetPath: string;
    step: 0 | 1 | 2 | 3| 4| 5| 6;
    kyc: RegisterStockLoanRequest;
    brwrCiId:number;
    brwrQA:string;
    rmsAccountStock:string;
    rmsAccountNo:string;
    amountRequest:number;
    periodRequest:number;
    rate:number;
    loanType:string;
    accountType:string;
  };
}

const initBrwrRegister = (): BrwrRegisterState["second"] => {
  return {
    targetPath: "",
    brwrCiId:0,
    step: 0,
    brwrQA:"",
    rmsAccountNo:'',
    rmsAccountStock:'',
    amountRequest:0,
    periodRequest:0,
    rate:0,
    loanType:"",
    accountType:"",
    kyc: {
      name: "",
      englishName: "",
      address: "",
      addressDetail: "",
      zip: "",
      regNum: "",
      amlJobTypeCode: "",
      companyName: "",
      isSelfEmployed: false,
      selfEmployedNumber: "",
      selfEmployedJobCode: "",
      isLiveKorea: true,
      liveCountryCode: "KR",
      customerTypeCode: "08",
      countryCode: "",
      addressType: "",
      ivstSourceTypeCode: "",
      ivstSourceTypeName: "",
      ivstSourceContent: "",
      ivstPurposeCode: "",
      ivstPurposeName: "",
      ivstPurposeContent: "",
      isAgent: false,
      agentRltn: "",
      agentName: "",
      agentEnglishName: "",
      agentRegNum: "",
      agentCountryCode: "",
      agentLiveCountryCode: "",
      accountSbTrstId: "",
      accountTrstId: "",
      ocrTrstId: "",
      ocrTrstSecret: "",
      fileList:[],
      email:""

    },
  };
};

export const brwrRegisterModule: Module<BrwrRegisterState, RootState> = {
  namespaced: true,
  state: () => ({
    second: initBrwrRegister(),
  }),
  mutations: {
    SET_INFO_ETC(state, payload: RegisterBrwrInfoEtc) {
      state.second.step = 4;
      Object.entries(payload).forEach(([key, value]) => {
        state.second.kyc[key] = value;
      });
    },
    SET_BRWR_QA(
      state,
      payload: { answers:string }
    ){
      state.second.step=3;
       state.second.brwrQA = payload.answers;
    },
    SET_OCR(
      state,
      payload: { ocrTrstId: string; ocrTrstSecret: string; regNum: string }
    ) {
      state.second.step = 5;
      state.second.kyc.ocrTrstId = payload.ocrTrstId;
      state.second.kyc.ocrTrstSecret = payload.ocrTrstSecret;
      state.second.kyc.regNum = payload.regNum;
    },
    SET_BANK(
      state,
      payload: { accountSbTrstId: string; accountTrstId: string }
    ) {
      (state.second.kyc.accountSbTrstId = payload.accountSbTrstId),
        (state.second.kyc.accountTrstId = payload.accountTrstId);
    },
    SET_STEP(state, paylod: BrwrRegisterState["second"]["step"]) {
      state.second.step = paylod;
    },
    SET_TARGET_ROUTE(state, paylod: string) {
      state.second.targetPath = paylod;
    },
    INIT_BRWR_REGISTER(state) {
      state.second = initBrwrRegister();
    },
    SET_PRE_BRWR_CI_ID(state,id:number){
      state.second.brwrCiId = id;
    },
    SET_RMS_INFO(state ,payload: {
      name:string,
      regNum:string,
      loanType:string,
      accountType:string,
      bankId: string,
      accountNumber: string,
      rate:number,
      amount:number,
      period:number
    }){
      state.second.rmsAccountStock = payload.bankId;
      state.second.rmsAccountNo = payload.accountNumber;
      state.second.amountRequest = payload.amount;
      state.second.periodRequest = payload.period;
      state.second.rate = payload.rate;
      state.second.accountType = payload.accountType;
      state.second.loanType = payload.loanType;
      state.second.kyc["name"] = payload.name;
      state.second.kyc["regNum"] = payload.regNum;
      state.second.step = 2;
    }
  },
  getters: {
    getStockloanSubmitForm: (state) => {
      const kyc = state.second.kyc;
      state.second.step=6;
      const submitForm ={

        name: kyc.name,
        englishName: kyc.englishName,
        regNum: kyc.regNum,
        address: kyc.address,
        addressDetail: kyc.addressDetail,
        zip: kyc.zip,
        accountSbTrstId: kyc.accountSbTrstId,
        accountTrstId: kyc.accountTrstId,
        ocrTrstId: kyc.ocrTrstId,
        ocrTrstSecret: kyc.ocrTrstSecret,
        brwrCi:state.second.brwrCiId,
        FileList: kyc.fileList,
        rmsAccountStock:state.second.rmsAccountStock,
        rmsAccountNo:state.second.rmsAccountNo,
        amountRequest:state.second.amountRequest,
        periodRequest:state.second.periodRequest,
        rate:state.second.rate,
        loanType:state.second.loanType,
        accountType:state.second.accountType,
        brwrQA:state.second.brwrQA,
        email:kyc.email
      };
      console.log(submitForm);
      return submitForm;
    },
    
  },
};
