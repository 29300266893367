import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/stockloan",
    name: "introduceStoackLoan",
    component: () => import("@/components/pages/stockLoan/Introduce.vue"),
    meta: {
      layout: "common",
    },
  }, 
  {
    path: "/stockloan/checkCi",
    name: "brwrCheckCi",
    component: () => import("@/components/pages/stockLoan/brwrRegister/CheckCI.vue"),
    meta: {
      layout: "blank",
    },
  }, {
    path: "/stockloan/register/:step",
    name: "StockLoanBrwrRegister",
    component: () =>
      import(
        "@/components/pages/stockLoan/brwrRegister/StockBrwrRegisterView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
    },
  },
];

export default routes;
