import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomeRoute from "./routes/home_routes";
import LoginRoute from "./routes/login_routes";
import FindUserRoute from "./routes/find_routes";
import RegisterRoute from "./routes/register_routes";
import SupportRoute from "./routes/support_routes";
import IvmtRoute from "./routes/ivmt_routes";
import TradeRoute from "./routes/trade_routes";
import MyLoungeRoute from "./routes/mylounge_routes";
import UserInfoRoute from "./routes/userinfo_routes";
import IntroduceRoutes from "./routes/introduce_routes";
import TermsRoutes from "./routes/terms_routes";
import TermsRoutes2411 from "./routes/terms_routes_before241113";
import ErrorRoutes from "@/router/routes/error_routes";
import StockLoanRoutes from "@/router/routes/stockloan_routes"
import { checkIsKyc, useUserLoginInfo } from "@/script/auth/authScript";

const routes: Array<RouteRecordRaw> = [
  ...HomeRoute,
  ...LoginRoute,
  ...FindUserRoute,
  ...IvmtRoute,
  ...RegisterRoute,
  ...SupportRoute,
  ...MyLoungeRoute,
  ...UserInfoRoute,
  ...IntroduceRoutes,
  ...TermsRoutes,
  ...ErrorRoutes,
  ...TradeRoute,
  ...StockLoanRoutes,
  ...TermsRoutes2411
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash && savedPosition) {
      document.body.scrollTo(savedPosition.top, savedPosition.left);
      return savedPosition;
    } else if (!to.hash) {
      // document.body.style.scrollBehavior = "auto";
      document.body.scrollTo({ top: 0, left: 0 });
      // document.body.style.scrollBehavior = "smooth";
    }
  },
});

router.beforeEach(async (to, from) => {
  // 로그인 성공시 로그인페이지 벗어나기.
  const { myDataResponse } = await useUserLoginInfo(to);
  if (myDataResponse.isLogin) {
    if (
      to.name == "login" ||
      to.name == "find" ||
      to.name == "findemail" ||
      to.name == "findpassword" ||
      to.name == "findepasswordreset" ||
      to.name == "register"
    ) {
      router.push("/");
    } else if (to.meta.kyc) {
      await checkIsKyc(to.path);
    }
  } else {
    if (to.meta.auth) {
      router.push(`/login?target=${to.fullPath}`);
    }
  }
});
router.onError((error) => {
  console.error(error);
  if (error.name === 'ChunkLoadError') {
    console.error("ChunkLoadError");
    window.location.replace(window.location.href);
  }
});
export default router;
