import { SecondRegisterKycForm } from "@/models/authModels";
import { RegisterSecondRequest } from "@/models/request/authRequest";
import { Module } from "vuex";
import { RootState } from "../index";

export interface BrwrRegisterState {
  second: {
    targetPath: string;
    step: 0 | 1 | 2 | 3| 4;
    kyc: RegisterSecondRequest;
    brwrCiId:number;
    brwrQA:string;
    rmsAccountStock:string;
    rmsAccountNo:string;
    amountRequest:number;
    periodRequest:number;
  };
}

const initBrwrRegister = (): BrwrRegisterState["second"] => {
  return {
    targetPath: "",
    brwrCiId:0,
    step: 0,
    brwrQA:'',
    rmsAccountNo:'',
    rmsAccountStock:'',
    amountRequest:0,
    periodRequest:0,
    kyc: {
      name: "",
      englishName: "",
      address: "",
      addressDetail: "",
      zip: "",
      regNum: "",
      amlJobTypeCode: "",
      companyName: "",
      isSelfEmployed: false,
      selfEmployedNumber: "",
      selfEmployedJobCode: "",
      isLiveKorea: true,
      liveCountryCode: "KR",
      customerTypeCode: "08",
      countryCode: "",
      addressType: "",
      ivstSourceTypeCode: "",
      ivstSourceTypeName: "",
      ivstSourceContent: "",
      ivstPurposeCode: "",
      ivstPurposeName: "",
      ivstPurposeContent: "",
      isAgent: false,
      agentRltn: "",
      agentName: "",
      agentEnglishName: "",
      agentRegNum: "",
      agentCountryCode: "",
      agentLiveCountryCode: "",
      accountSbTrstId: "",
      accountTrstId: "",
      ocrTrstId: "",
      ocrTrstSecret: "",
    },
  };
};

export const brwrRegisterModule: Module<BrwrRegisterState, RootState> = {
  namespaced: true,
  state: () => ({
    second: initBrwrRegister(),
  }),
  mutations: {
    SET_KYC(state, payload: SecondRegisterKycForm) {
      state.second.step = 1;
      Object.entries(payload).forEach(([key, value]) => {
        state.second.kyc[key] = value;
      });
    },
    SET_OCR(
      state,
      payload: { ocrTrstId: string; ocrTrstSecret: string; regNum: string }
    ) {
      state.second.kyc.ocrTrstId = payload.ocrTrstId;
      state.second.kyc.ocrTrstSecret = payload.ocrTrstSecret;
      state.second.kyc.regNum = payload.regNum;
    },
    SET_BANK(
      state,
      payload: { accountSbTrstId: string; accountTrstId: string }
    ) {
      (state.second.kyc.accountSbTrstId = payload.accountSbTrstId),
        (state.second.kyc.accountTrstId = payload.accountTrstId);
    },
    SET_STEP(state, paylod: BrwrRegisterState["second"]["step"]) {
      state.second.step = paylod;
    },
    SET_TARGET_ROUTE(state, paylod: string) {
      state.second.targetPath = paylod;
    },
    INIT_SECOND_REGISTER(state) {
      state.second = initBrwrRegister();
    },
    SET_PRE_BRWR_CI_ID(state,id:number){
      state.second.brwrCiId = id;
    },
    SET_RMS_INFO(state ,payload: {stock:string,accountNo:string,amountRequest:number,periodRequest:number}){
      state.second.rmsAccountStock = payload.stock;
      state.second.rmsAccountNo = payload.accountNo;
      state.second.amountRequest = payload.amountRequest;
      state.second.periodRequest = payload.periodRequest;
    }
  },
  getters: {
    getSecondSubmitForm: (state) => {
      const kyc = state.second.kyc;
      return {
        name: kyc.name,
        englishName: kyc.englishName,
        regNum: kyc.regNum,
        brwrCi:state.second.brwrCiId,
        amlData: {
          amlJobTypeCode: kyc.amlJobTypeCode,
          companyName: kyc.companyName,
          isSelfEmployed: kyc.isSelfEmployed,
          selfEmployedNumber: kyc.selfEmployedNumber,
          selfEmployedJobCode: kyc.selfEmployedJobCode,
          isLiveKorea: kyc.isLiveKorea,
          liveCountryCode: kyc.liveCountryCode,
          customerTypeCode: kyc.customerTypeCode,
          customerIndvCdd: {
            address: kyc.address,
            addressDetail: kyc.addressDetail,
            zip: kyc.zip,
            countryCode: kyc.countryCode,
            addressType: kyc.addressType,
          },
          customerIndvEdd: {
            ivstSourceTypeCode: kyc.ivstSourceTypeCode,
            ivstSourceTypeName: kyc.ivstSourceTypeName,
            ivstSourceContent: kyc.ivstSourceContent,
            ivstPurposeCode: kyc.ivstPurposeCode,
            ivstPurposeName: kyc.ivstPurposeName,
            ivstPurposeContent: kyc.ivstPurposeContent,
          },
          amlAgent: {
            isAgent: kyc.isAgent,
            rltn: kyc.agentRltn,
            name: kyc.agentName,
            englishName: kyc.agentEnglishName,
            regNum: kyc.agentRegNum,
            countryCode: kyc.agentCountryCode,
            liveCountryCode: kyc.agentLiveCountryCode,
          },
        },
        accountSbTrstId: kyc.accountSbTrstId,
        accountTrstId: kyc.accountTrstId,
        ocrTrstId: kyc.ocrTrstId,
        ocrTrstSecret: kyc.ocrTrstSecret,
      };
    },
    
  },
};
