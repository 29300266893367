import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/terms/service2411",
    name: "termsofservice2411",
    component: () => import("@/components/pages/terms/terms_before_241113/TermsOfServiceView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },


  {
    path: "/terms/linkedloancontract2411",
    name: "termsoflinkedloancontract2411",
    component: () =>
      import("@/components/pages/terms/terms_before_241113/TermsOfLinkedLoanContractView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },

  {
    path: "/terms/linkedivmtcontract2411",
    name: "termsoflinkedivmtcontract2411",
    component: () =>
      import("@/components/pages/terms/terms_before_241113/TermsOfLinkedIvmtContractView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },

  
  {
    path: "/terms/personalcreditinfopolicy2411",
    name: "personalcreditpolicy2411",
    component: () =>
      import(
        "@/components/pages/terms/terms_before_241113/TermsOfPersonalCreditInfoProcessingPolicyView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/personalinfosys2411",
    name: "personalinfosys2411",
    component: () =>
      import(
        "@/components/pages/terms/terms_before_241113/TermsOfPersonalCreditInfoSys.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsncreditinfoprvdathirdparty2411",
    name: "prsncreditinfoprvdathirdparty2411",
    component: () =>
      import(
        "@/components/pages/terms/terms_before_241113/TermsOfPrsnCreditInfoPrvdAThirdPartyView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsnregno2411",
    name: "prsnregno2411",
    component: () =>
      import(
        "@/components/pages/terms/terms_before_241113/TermsOfPersonalRegNoView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsnmarketing2411",
    name: "prsnmarketing2411",
    component: () =>
      import(
        "@/components/pages/terms/terms_before_241113/TermsOfPersonalMarketing.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  }
];

export default routes;
