import { reactive, ref, computed } from "vue";
import navBarMenu from "@/components/layout/bar/navBarMenu";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { NavMenuItem } from "@/models/commonModels";
import { message, notification } from "ant-design-vue";

export const useNavbar = () => {
  const store = useStore();
  const router = useRouter();

  const isOpen = computed(() => store.state.open.navbar.isOpen);
  const headerElement = ref();
  const navElement = ref();
  const navBarElement = ref();
  const toggleNav = (status: boolean) => {
    if (status) {
      const style = getComputedStyle(navElement.value);
      navElement.value.style.width = `${
        headerElement.value.offsetWidth + 50
      }px`;
    }
    store.commit("open/CONFIG_NAVBAR", status);
  };
  const routeNavbar = (routeName: string) => {
    if(routeName=="READY"){
      message.info("서비스를 준비중 입니다. 고객센터로 문의주시면 감사하겠습니다.");
      return;
    }

    router.push({ name: routeName });
    store.commit("open/CONFIG_NAVBAR", false);
  };

  return {
    isOpen,
    toggleNav,
    routeNavbar,
    headerElement,
    navElement,
    navBarElement,
    navBarMenu,
  };
};

export const useMobileSideBar = () => {
  const store = useStore();

  const isOpen = computed(() => {
    return store.state.open.mobileSidebar.isOpen;
  });
  const toggleSidebar = () => {
    store.commit("open/TOGGLE_MOBILE_SIDEBAR");
  };

  return {
    isOpen,
    toggleSidebar,
  };
};

export const useMobileUserMenu = () => {
  const store = useStore();

  const isOpen = computed(() => {
    return store.state.open.mobileUserMenu.isOpen;
  });
  const toggleUserMenu = () => {
    store.commit("open/TOGGLE_MOBILE_USER_MENU");
  };
  return {
    isOpen,
    toggleUserMenu,
  };
};

export const useMobileNav = () => {
  const store = useStore();
  const router = useRouter();

  const menuList = reactive(
    navBarMenu.map((menu) => {
      return {
        ...menu,
        isOpen: false,
      };
    })
  );

  const sidebarRouter = (routerName: string) => {
    router.push({ name: routerName });
    store.commit("open/CONFIG_MOBILE_SIDEBAR", false);
    store.commit("open/CONFIG_MOBILE_USER_MENU", false);
  };

  const clickMenu = (menu: NavMenuItem, index: number) => {
    /*if(menu.title=="고객라운지"){
      notification.info({
        message:"준비중 입니다."
      });
      return ;
    }*/
    if (menu.subMenu) {
      menuList[index].isOpen = !menuList[index].isOpen;
    } else {
      sidebarRouter(menu.route);
    }
  };

  return {
    menuList,
    clickMenu,
    sidebarRouter,
  };
};
